/* File automatically generated by yarn generate:graphql */
/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  jsonb: string[];
  timestamptz: string;
  uuid: string;
};

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq: Maybe<Scalars['String']>;
  _gt: Maybe<Scalars['String']>;
  _gte: Maybe<Scalars['String']>;
  _ilike: Maybe<Scalars['String']>;
  _in: Maybe<Array<Scalars['String']>>;
  _is_null: Maybe<Scalars['Boolean']>;
  _like: Maybe<Scalars['String']>;
  _lt: Maybe<Scalars['String']>;
  _lte: Maybe<Scalars['String']>;
  _neq: Maybe<Scalars['String']>;
  _nilike: Maybe<Scalars['String']>;
  _nin: Maybe<Array<Scalars['String']>>;
  _nlike: Maybe<Scalars['String']>;
  _nsimilar: Maybe<Scalars['String']>;
  _similar: Maybe<Scalars['String']>;
};

/** columns and relationships of "answers" */
export type Answers = {
  answers: Scalars['jsonb'];
  id: Scalars['uuid'];
  question_id: Scalars['String'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};


/** columns and relationships of "answers" */
export type AnswersAnswersArgs = {
  path: Maybe<Scalars['String']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Answers_Append_Input = {
  answers: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "answers" */
export type Answers_Arr_Rel_Insert_Input = {
  data: Array<Answers_Insert_Input>;
  on_conflict: Maybe<Answers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "answers". All fields are combined with a logical 'AND'. */
export type Answers_Bool_Exp = {
  _and: Maybe<Array<Maybe<Answers_Bool_Exp>>>;
  _not: Maybe<Answers_Bool_Exp>;
  _or: Maybe<Array<Maybe<Answers_Bool_Exp>>>;
  answers: Maybe<Jsonb_Comparison_Exp>;
  id: Maybe<Uuid_Comparison_Exp>;
  question_id: Maybe<String_Comparison_Exp>;
  user: Maybe<Users_Bool_Exp>;
  user_id: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "answers" */
export enum Answers_Constraint {
  /** unique or primary key constraint */
  AnswersPkey = 'answers_pkey',
  /** unique or primary key constraint */
  AnswersUserIdQuestionIdKey = 'answers_user_id_question_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Answers_Delete_At_Path_Input = {
  answers: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Answers_Delete_Elem_Input = {
  answers: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Answers_Delete_Key_Input = {
  answers: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "answers" */
export type Answers_Insert_Input = {
  answers: Maybe<Scalars['jsonb']>;
  question_id: Maybe<Scalars['String']>;
  user: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "answers" */
export type Answers_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Answers>;
};

/** input type for inserting object relation for remote table "answers" */
export type Answers_Obj_Rel_Insert_Input = {
  data: Answers_Insert_Input;
  on_conflict: Maybe<Answers_On_Conflict>;
};

/** on conflict condition type for table "answers" */
export type Answers_On_Conflict = {
  constraint: Answers_Constraint;
  update_columns: Array<Answers_Update_Column>;
  where: Maybe<Answers_Bool_Exp>;
};

/** ordering options when selecting data from "answers" */
export type Answers_Order_By = {
  answers: Maybe<Order_By>;
  id: Maybe<Order_By>;
  question_id: Maybe<Order_By>;
  user: Maybe<Users_Order_By>;
  user_id: Maybe<Order_By>;
};

/** primary key columns input for table: "answers" */
export type Answers_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Answers_Prepend_Input = {
  answers: Maybe<Scalars['jsonb']>;
};

/** select columns of table "answers" */
export enum Answers_Select_Column {
  /** column name */
  Answers = 'answers',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "answers" */
export type Answers_Set_Input = {
  answers: Maybe<Scalars['jsonb']>;
};

/** update columns of table "answers" */
export enum Answers_Update_Column {
  /** column name */
  Answers = 'answers'
}


/** expression to compare columns of type jsonb. All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  /** is the column contained in the given json value */
  _contained_in: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains: Maybe<Scalars['jsonb']>;
  _eq: Maybe<Scalars['jsonb']>;
  _gt: Maybe<Scalars['jsonb']>;
  _gte: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any: Maybe<Array<Scalars['String']>>;
  _in: Maybe<Array<Scalars['jsonb']>>;
  _is_null: Maybe<Scalars['Boolean']>;
  _lt: Maybe<Scalars['jsonb']>;
  _lte: Maybe<Scalars['jsonb']>;
  _neq: Maybe<Scalars['jsonb']>;
  _nin: Maybe<Array<Scalars['jsonb']>>;
};

/** mutation root */
export type Mutation_Root = {
  /** insert a single row into the table: "answers" */
  addAnswer: Maybe<Answers>;
  /** insert a single row into the table: "users" */
  addUser: Maybe<Users>;
  /** insert data into the table: "answers" */
  insert_answers: Maybe<Answers_Mutation_Response>;
  /** insert data into the table: "users" */
  insert_users: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  updateUser: Maybe<Users>;
  /** update data of the table: "answers" */
  update_answers: Maybe<Answers_Mutation_Response>;
  /** update single row of the table: "answers" */
  update_answers_by_pk: Maybe<Answers>;
  /** update data of the table: "users" */
  update_users: Maybe<Users_Mutation_Response>;
};


/** mutation root */
export type Mutation_RootAddAnswerArgs = {
  object: Answers_Insert_Input;
  on_conflict: Maybe<Answers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootAddUserArgs = {
  object: Users_Insert_Input;
  on_conflict: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AnswersArgs = {
  objects: Array<Answers_Insert_Input>;
  on_conflict: Maybe<Answers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdateUserArgs = {
  _set: Maybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AnswersArgs = {
  _append: Maybe<Answers_Append_Input>;
  _delete_at_path: Maybe<Answers_Delete_At_Path_Input>;
  _delete_elem: Maybe<Answers_Delete_Elem_Input>;
  _delete_key: Maybe<Answers_Delete_Key_Input>;
  _prepend: Maybe<Answers_Prepend_Input>;
  _set: Maybe<Answers_Set_Input>;
  where: Answers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Answers_By_PkArgs = {
  _append: Maybe<Answers_Append_Input>;
  _delete_at_path: Maybe<Answers_Delete_At_Path_Input>;
  _delete_elem: Maybe<Answers_Delete_Elem_Input>;
  _delete_key: Maybe<Answers_Delete_Key_Input>;
  _prepend: Maybe<Answers_Prepend_Input>;
  _set: Maybe<Answers_Set_Input>;
  pk_columns: Answers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _set: Maybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};

/** column ordering options */
export enum Order_By {
  /** in the ascending order, nulls last */
  Asc = 'asc',
  /** in the ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in the ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in the descending order, nulls first */
  Desc = 'desc',
  /** in the descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in the descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** query root */
export type Query_Root = {
  /** fetch data from the table: "answers" */
  answers: Array<Answers>;
  /** fetch data from the table: "answers" using primary key columns */
  answers_by_pk: Maybe<Answers>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk: Maybe<Users>;
};


/** query root */
export type Query_RootAnswersArgs = {
  distinct_on: Maybe<Array<Answers_Select_Column>>;
  limit: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
  order_by: Maybe<Array<Answers_Order_By>>;
  where: Maybe<Answers_Bool_Exp>;
};


/** query root */
export type Query_RootAnswers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootUsersArgs = {
  distinct_on: Maybe<Array<Users_Select_Column>>;
  limit: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
  order_by: Maybe<Array<Users_Order_By>>;
  where: Maybe<Users_Bool_Exp>;
};


/** query root */
export type Query_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_Root = {
  /** fetch data from the table: "answers" */
  answers: Array<Answers>;
  /** fetch data from the table: "answers" using primary key columns */
  answers_by_pk: Maybe<Answers>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk: Maybe<Users>;
};


/** subscription root */
export type Subscription_RootAnswersArgs = {
  distinct_on: Maybe<Array<Answers_Select_Column>>;
  limit: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
  order_by: Maybe<Array<Answers_Order_By>>;
  where: Maybe<Answers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAnswers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootUsersArgs = {
  distinct_on: Maybe<Array<Users_Select_Column>>;
  limit: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
  order_by: Maybe<Array<Users_Order_By>>;
  where: Maybe<Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq: Maybe<Scalars['timestamptz']>;
  _gt: Maybe<Scalars['timestamptz']>;
  _gte: Maybe<Scalars['timestamptz']>;
  _in: Maybe<Array<Scalars['timestamptz']>>;
  _is_null: Maybe<Scalars['Boolean']>;
  _lt: Maybe<Scalars['timestamptz']>;
  _lte: Maybe<Scalars['timestamptz']>;
  _neq: Maybe<Scalars['timestamptz']>;
  _nin: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "users" */
export type Users = {
  /** An array relationship */
  answers: Array<Answers>;
  email: Scalars['String'];
  finished_at: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
};


/** columns and relationships of "users" */
export type UsersAnswersArgs = {
  distinct_on: Maybe<Array<Answers_Select_Column>>;
  limit: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
  order_by: Maybe<Array<Answers_Order_By>>;
  where: Maybe<Answers_Bool_Exp>;
};

/** input type for inserting array relation for remote table "users" */
export type Users_Arr_Rel_Insert_Input = {
  data: Array<Users_Insert_Input>;
  on_conflict: Maybe<Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and: Maybe<Array<Maybe<Users_Bool_Exp>>>;
  _not: Maybe<Users_Bool_Exp>;
  _or: Maybe<Array<Maybe<Users_Bool_Exp>>>;
  answers: Maybe<Answers_Bool_Exp>;
  email: Maybe<String_Comparison_Exp>;
  finished_at: Maybe<Timestamptz_Comparison_Exp>;
  id: Maybe<Uuid_Comparison_Exp>;
  name: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint */
  UsersEmailKey = 'users_email_key',
  /** unique or primary key constraint */
  UsersPkey = 'users_pkey'
}

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  answers: Maybe<Answers_Arr_Rel_Insert_Input>;
  email: Maybe<Scalars['String']>;
  finished_at: Maybe<Scalars['timestamptz']>;
  name: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  on_conflict: Maybe<Users_On_Conflict>;
};

/** on conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns: Array<Users_Update_Column>;
  where: Maybe<Users_Bool_Exp>;
};

/** ordering options when selecting data from "users" */
export type Users_Order_By = {
  email: Maybe<Order_By>;
  finished_at: Maybe<Order_By>;
  id: Maybe<Order_By>;
  name: Maybe<Order_By>;
};

/** primary key columns input for table: "users" */
export type Users_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  Email = 'email',
  /** column name */
  FinishedAt = 'finished_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  finished_at: Maybe<Scalars['timestamptz']>;
  name: Maybe<Scalars['String']>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  FinishedAt = 'finished_at',
  /** column name */
  Name = 'name'
}


/** expression to compare columns of type uuid. All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq: Maybe<Scalars['uuid']>;
  _gt: Maybe<Scalars['uuid']>;
  _gte: Maybe<Scalars['uuid']>;
  _in: Maybe<Array<Scalars['uuid']>>;
  _is_null: Maybe<Scalars['Boolean']>;
  _lt: Maybe<Scalars['uuid']>;
  _lte: Maybe<Scalars['uuid']>;
  _neq: Maybe<Scalars['uuid']>;
  _nin: Maybe<Array<Scalars['uuid']>>;
};

export type GetAnswerQueryVariables = Exact<{
  questionId: Maybe<Scalars['String']>;
  userId: Maybe<Scalars['uuid']>;
}>;


export type GetAnswerQuery = { answers: Array<Pick<Answers, 'id' | 'answers'>> };

export type AddAnswerMutationVariables = Exact<{
  answers: Maybe<Scalars['jsonb']>;
  questionId: Maybe<Scalars['String']>;
  userId: Maybe<Scalars['uuid']>;
}>;


export type AddAnswerMutation = { addAnswer: Maybe<Pick<Answers, 'id' | 'answers'>> };

export type InsertUserMutationVariables = Exact<{
  email: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
}>;


export type InsertUserMutation = { user: Maybe<(
    Pick<Users, 'id' | 'email' | 'name'>
    & { finishedAt: Users['finished_at'] }
  )> };

export type FinishQuizMutationVariables = Exact<{
  id: Scalars['uuid'];
  finishedAt: Scalars['timestamptz'];
}>;


export type FinishQuizMutation = { user: Maybe<(
    Pick<Users, 'id' | 'email' | 'name'>
    & { finishedAt: Users['finished_at'] }
  )> };


export const GetAnswerDocument = gql`
    query GetAnswer($questionId: String, $userId: uuid) {
  answers(where: {question_id: {_eq: $questionId}, user_id: {_eq: $userId}}) {
    id
    answers
  }
}
    `;

/**
 * __useGetAnswerQuery__
 *
 * To run a query within a React component, call `useGetAnswerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnswerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnswerQuery({
 *   variables: {
 *      questionId: // value for 'questionId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetAnswerQuery(baseOptions?: Apollo.QueryHookOptions<GetAnswerQuery, GetAnswerQueryVariables>) {
        return Apollo.useQuery<GetAnswerQuery, GetAnswerQueryVariables>(GetAnswerDocument, baseOptions);
      }
export function useGetAnswerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnswerQuery, GetAnswerQueryVariables>) {
          return Apollo.useLazyQuery<GetAnswerQuery, GetAnswerQueryVariables>(GetAnswerDocument, baseOptions);
        }
export type GetAnswerQueryHookResult = ReturnType<typeof useGetAnswerQuery>;
export type GetAnswerLazyQueryHookResult = ReturnType<typeof useGetAnswerLazyQuery>;
export type GetAnswerQueryResult = Apollo.QueryResult<GetAnswerQuery, GetAnswerQueryVariables>;
export const AddAnswerDocument = gql`
    mutation AddAnswer($answers: jsonb, $questionId: String, $userId: uuid) {
  addAnswer(
    object: {answers: $answers, question_id: $questionId, user_id: $userId}
    on_conflict: {constraint: answers_user_id_question_id_key, update_columns: answers}
  ) {
    id
    answers
  }
}
    `;
export type AddAnswerMutationFn = Apollo.MutationFunction<AddAnswerMutation, AddAnswerMutationVariables>;

/**
 * __useAddAnswerMutation__
 *
 * To run a mutation, you first call `useAddAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAnswerMutation, { data, loading, error }] = useAddAnswerMutation({
 *   variables: {
 *      answers: // value for 'answers'
 *      questionId: // value for 'questionId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAddAnswerMutation(baseOptions?: Apollo.MutationHookOptions<AddAnswerMutation, AddAnswerMutationVariables>) {
        return Apollo.useMutation<AddAnswerMutation, AddAnswerMutationVariables>(AddAnswerDocument, baseOptions);
      }
export type AddAnswerMutationHookResult = ReturnType<typeof useAddAnswerMutation>;
export type AddAnswerMutationResult = Apollo.MutationResult<AddAnswerMutation>;
export type AddAnswerMutationOptions = Apollo.BaseMutationOptions<AddAnswerMutation, AddAnswerMutationVariables>;
export const InsertUserDocument = gql`
    mutation InsertUser($email: String, $name: String) {
  user: addUser(
    object: {email: $email, name: $name}
    on_conflict: {constraint: users_email_key, update_columns: name}
  ) {
    id
    email
    name
    finishedAt: finished_at
  }
}
    `;
export type InsertUserMutationFn = Apollo.MutationFunction<InsertUserMutation, InsertUserMutationVariables>;

/**
 * __useInsertUserMutation__
 *
 * To run a mutation, you first call `useInsertUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertUserMutation, { data, loading, error }] = useInsertUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useInsertUserMutation(baseOptions?: Apollo.MutationHookOptions<InsertUserMutation, InsertUserMutationVariables>) {
        return Apollo.useMutation<InsertUserMutation, InsertUserMutationVariables>(InsertUserDocument, baseOptions);
      }
export type InsertUserMutationHookResult = ReturnType<typeof useInsertUserMutation>;
export type InsertUserMutationResult = Apollo.MutationResult<InsertUserMutation>;
export type InsertUserMutationOptions = Apollo.BaseMutationOptions<InsertUserMutation, InsertUserMutationVariables>;
export const FinishQuizDocument = gql`
    mutation FinishQuiz($id: uuid!, $finishedAt: timestamptz!) {
  user: updateUser(pk_columns: {id: $id}, _set: {finished_at: $finishedAt}) {
    id
    email
    name
    finishedAt: finished_at
  }
}
    `;
export type FinishQuizMutationFn = Apollo.MutationFunction<FinishQuizMutation, FinishQuizMutationVariables>;

/**
 * __useFinishQuizMutation__
 *
 * To run a mutation, you first call `useFinishQuizMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishQuizMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishQuizMutation, { data, loading, error }] = useFinishQuizMutation({
 *   variables: {
 *      id: // value for 'id'
 *      finishedAt: // value for 'finishedAt'
 *   },
 * });
 */
export function useFinishQuizMutation(baseOptions?: Apollo.MutationHookOptions<FinishQuizMutation, FinishQuizMutationVariables>) {
        return Apollo.useMutation<FinishQuizMutation, FinishQuizMutationVariables>(FinishQuizDocument, baseOptions);
      }
export type FinishQuizMutationHookResult = ReturnType<typeof useFinishQuizMutation>;
export type FinishQuizMutationResult = Apollo.MutationResult<FinishQuizMutation>;
export type FinishQuizMutationOptions = Apollo.BaseMutationOptions<FinishQuizMutation, FinishQuizMutationVariables>;